import React, { useEffect } from 'react'

// components
import { NavLink } from 'react-router-dom'

// styles
import CSS_FOOTER from './Footer.module.scss'

// component
const Footer = () => {
    // TODO: Transform componentDidMount to useEffect

    useEffect(() => {
        let deferredPrompt
        const addBtn = document.querySelector('.footer__add')
        addBtn.style.display = 'none'
        window.addEventListener('beforeinstallprompt', e => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault()
            // Stash the event so it can be triggered later.
            deferredPrompt = e
            // Update UI to notify the user they can add to home screen
            addBtn.style.display = 'block'

            addBtn.addEventListener('click', e => {
                // hide our user interface that shows our A2HS button
                addBtn.style.display = 'none'
                // Show the prompt
                deferredPrompt.prompt()
                // Wait for the user to respond to the prompt
                deferredPrompt.userChoice.then(choiceResult => {
                    if (choiceResult.outcome === 'accepted') {
                        console.log('User accepted the A2HS prompt')
                    } else {
                        console.log('User dismissed the A2HS prompt')
                    }
                    deferredPrompt = null
                })
            })
        })
    })

    return (
        <footer className={CSS_FOOTER.footer}>
            <div className={CSS_FOOTER.nav}>
                <NavLink className={CSS_FOOTER.navLink} to={'/'}>
                    Home
                </NavLink>
                &nbsp;-&nbsp;
                <NavLink className={CSS_FOOTER.navLink} to={'/users'}>
                    Users
                </NavLink>
                &nbsp;-&nbsp;
                <NavLink className={CSS_FOOTER.navLink} to={'/profile'}>
                    Profile
                </NavLink>
                &nbsp;-&nbsp;
                <NavLink className={CSS_FOOTER.navLink} to={'/about'}>
                    About
                </NavLink>
                &nbsp;-&nbsp;
                <NavLink className={CSS_FOOTER.navLink} to={'#!'}>
                    Legal privacy
                </NavLink>
                &nbsp;-&nbsp;
                <NavLink className={CSS_FOOTER.navLink} to={'#!'}>
                    Legal terms
                </NavLink>
            </div>
            <p className={CSS_FOOTER.legacy}>Copyright © 2019 Topizr Inc. All rights reserved.</p>
            <button className={`footer__add ${CSS_FOOTER.add}`}>Add to home screen</button>
        </footer>
    )
}

export default Footer
