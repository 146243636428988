import React, { Fragment, useState, useEffect } from 'react'

// components
import { Redirect } from 'react-router-dom'

// styles
import './Search.scss'

// component
const Search = props => {
    const [getTop, setGetTop] = useState(false)
    const [query, setQuery] = useState('')
    const [initialized, setInitialized] = useState(false)

    const handleFocus = event => {
        setQuery('')
    }

    const handleSubmit = event => {
        setInitialized(false)
        event.preventDefault()
        // setQuery(searchfield.value)
        setGetTop(true)
    }

    useEffect(() => {
        if (!initialized) {
            if (getTop) {
                setGetTop(false)
                setInitialized(true)
            }
        }
    }, [initialized, getTop])

    // TODO: Transform it in "Form Handling with a Stateful React Form"
    return (
        <Fragment>
            <form className="search" onSubmit={handleSubmit}>
                <input
                    className="search__field"
                    required
                    minLength="3"
                    maxLength="15"
                    id="search__field"
                    type="text"
                    name="search__field"
                    aria-label="search__field"
                    value={query || ''}
                    onFocus={handleFocus}
                    onChange={event => setQuery(event.target.value)}
                    placeholder="SEARCH"
                />
                <button className="search__submit" type="submit">
                    OK
                </button>
            </form>
            {getTop ? <Redirect to={`/tops/${query}`} /> : <Fragment />}
        </Fragment>
    )
}

export default Search
