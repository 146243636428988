import React, { Fragment } from 'react'

// picture randomizer
import { randomPicture } from '../Functions'

// components
import Card from '../molecules/Card'
import Title from '../atoms/Title'

// component
const Top = props => {
    const top = props.match.params.top
    return (
        <Fragment>
            <Title type="h2">The top's {top}</Title>
            <Card authorPicture={randomPicture()} author="lorede" postPicture={randomPicture()} />
            <Card authorPicture={randomPicture()} author="ipsum_e" postPicture={randomPicture()} />
            <Card authorPicture={randomPicture()} author="Inodlorre" postPicture={randomPicture()} />
            <Card authorPicture={randomPicture()} author="Acquismete" postPicture={randomPicture()} />
            <Card authorPicture={randomPicture()} author="Pelletensque" postPicture={randomPicture()} />
        </Fragment>
    )
}

export default Top
