import React from 'react'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// styles
import CSS_VOTE from './Vote.module.scss'

// component
const Vote = props => {
    return (
        <div className={props.active ? `${CSS_VOTE.active} ${CSS_VOTE.vote}` : CSS_VOTE.vote}>
            <FontAwesomeIcon className={CSS_VOTE.color} icon="star" />
        </div>
    )
}

export default Vote
