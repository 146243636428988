import React from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// icons library
import './Icons'

// styles
import CSS_BUTTON from './Button.module.scss'

// component
const Button = ({ className, color, to, bold, fa, ariaLabel, children, type, onClick, onSubmit }) => {
    const htmlColor = color ? `${CSS_BUTTON.color}` : CSS_BUTTON.colorNone
    const htmlBold = bold ? CSS_BUTTON.bold : ''
    return (
        <button className={CSS_BUTTON.main} type={type ? 'submit' : 'text'} onClick={onClick}>
            <NavLink className={`${className || ''}  ${htmlBold + htmlColor}`} to={to || '#!'} aria-label={ariaLabel}>
                {fa ? <FontAwesomeIcon icon={fa} /> : <React.Fragment />}
                <span className="button__children">{children}</span>
            </NavLink>
        </button>
    )
}

export default Button
