import React, { Fragment } from 'react'

// components
import Card from '../molecules/Card'
import Title from '../atoms/Title'

// picture randomizer
import { randomPicture } from '../Functions'

// styles
import CSS_USER from './User.module.scss'

// component
const User = props => {
    const user = props.match.params.user

    return (
        <Fragment>
            <div className={CSS_USER.head}>
                <img src={randomPicture()} alt="" className={CSS_USER.picture} />
                <div className={CSS_USER.meta}>
                    <Title type="h2">{user}</Title>
                </div>
            </div>
            <Card top={'house'} postPicture={randomPicture()} />
            <Card top={'car'} postPicture={randomPicture()} />
            <Card top={'bike'} postPicture={randomPicture()} />
            <Card top={'bottle'} postPicture={randomPicture()} />
            <Card top={'creek'} postPicture={randomPicture()} />
            <Card top={'flower'} postPicture={randomPicture()} />
        </Fragment>
    )
}

export default User
