import React, { useState, Fragment } from 'react'

import './Textarea.scss'

const Textarea = ({ placeholder }) => {
  const { handleFocus, handleBlur, handleChange, shouldBlur } = events()
  const [text, setText] = useState(placeholder)

  const getClasses = text !== '' || text !== placeholder ? 'textarea editing' : 'textarea'

  function events() {
    const handleFocus = event => (text === placeholder ? setText('') : '')
    const handleBlur = event => (text === '' ? setText(placeholder) : text)
    const handleChange = event => {
      setText(event.target.value)
    }
    const shouldBlur = event => {
      if (event.keyCode === 13) event.target.blur()
    }
    return { handleFocus, handleBlur, shouldBlur, handleChange }
  }

  return (
    <Fragment>
      <label htmlFor={placeholder} className="hide">
        {placeholder}
      </label>
      <textarea type="text" name={placeholder} value={text} className={getClasses} onFocus={handleFocus} onBlur={handleBlur} onKeyDown={shouldBlur} onChange={handleChange} />
    </Fragment>
  )
}

export default Textarea
