import React from 'react'
import { Switch, Route } from 'react-router-dom'

import PageNotfound from 'components/pages/Notfound'
import PageAbout from 'components/pages/About'
import PageTops from 'components/pages/Tops'
import PageUsers from 'components/pages/Users'
import PageProfile from 'components/pages/Profile'
import PageNotifications from 'components/pages/Notifications'
import PageTop from 'components/pages/Top'
import PageUser from 'components/pages/User'
import PageHomepage from 'components/pages/Homepage'
import PageSettings from 'components/pages/Settings'
import PagePost from 'components/pages/Post'

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/" component={PageHomepage} />
            <Route exact path="/tops" component={PageTops} />
            <Route exact path="/tops/:top" component={PageTop} />
            <Route exact path="/(users|profiles)" component={PageUsers} />
            <Route exact path="/(users|profile)/:user" component={PageUser} />
            <Route exact path="/post" component={PagePost} />
            <Route exact path="/profile" component={PageProfile} />
            <Route exact path="/notifications" component={PageNotifications} />
            <Route exact path="/settings" component={PageSettings} />
            <Route exact path="/about" component={PageAbout} />
            <Route component={PageNotfound} />
        </Switch>
    )
}

export default Routes
