import React from 'react'

import SVGlogo from 'assets/logo.svg'

import CSS_LOGO from './Logo.module.scss'

const Logo = props => {
    return (
        <div className={CSS_LOGO.logo}>
            <img src={SVGlogo} alt="Topizr" className={CSS_LOGO.img} />
            <div className={CSS_LOGO.text}>topizr</div>
        </div>
    )
}

export default Logo
