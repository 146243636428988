import React from 'react'

// components
import { NavLink } from 'react-router-dom'
import Button from '../atoms/Button'
import Logo from '../atoms/Logo'
import Search from '../molecules/Search'

// styles
import './Header.scss'

// component
const Header = () => {
    return (
        <header className="header">
            <NavLink className="header__brand" exact to={'/'}>
                <Logo />
            </NavLink>
            <Search />
            <Button className="header__account" fa="bell" ariaLabel="Read more" to={'/notifications'}>
                <span className="header__account--text">Notification</span>
            </Button>
        </header>
    )
}

export default Header
