import { createStore } from 'redux'
import uuid from 'uuid/v4'
import { randomPicture } from '../components/Functions'

const initialState = {
    posts: [
        {
            id: uuid(),
            top: 'house',
            rank: 12,
            voted: false,
            date: 'DateString',
            author: {
                login: 'Kriffin',
                realname: 'Maxime Blanquart',
                picture: randomPicture,
            },
            picture: randomPicture,
            description: 'Ad consequuntur, omnis quaerat deleniti eos non velit asperiores. Praesentium, asperiores veniam.',
            taggedProfiles: ['Polki', 'Giver', 'Troapl'],
        },
        {
            id: uuid(),
            top: 'car',
            rank: 2,
            voted: true,
            date: 'DateString',
            author: {
                login: 'Kriffin',
                realname: 'Maxime Blanquart',
                picture: randomPicture,
            },
            picture: randomPicture,
            description: 'Deleniti eos non. Praesentium, asperiores veniam velit asperiores.',
            taggedProfiles: ['Polki', 'Giver', 'Troapl'],
        },
    ],
    users: [
        {
            login: 'Kriffin',
            realname: 'Maxime Blanquart',
            picture: randomPicture,
            email: 'a050a37aac02b8fa4db6a009156c962e',
            password: 'c1e242a344781aa9d2dca6412bd187bf',
            posts: [
                {
                    created: [2, 156165, 6514, 424, 65, 4625, 196, 854, 196, 5, 42, 624, 54],
                    liked: [1516, 651, 21, 651, 65, 46, 5465, 46, 5165, 743, 72, 1, 951, 95, 6, 7451, 5, 65, 7541, 9, 5, 1],
                },
            ],
            tops: [
                {
                    created: [
                        {
                            'Lorem ipsum': randomPicture,
                            'consectetur adipisicing': randomPicture,
                            'Repudiandae accusantium': randomPicture,
                            Doloremque: randomPicture,
                            Omnis: randomPicture,
                            'Perferendis itaque': randomPicture,
                        },
                    ],
                    subscribed: ['Oiudfrhg', 'Erzskjghdg', 'Esoidsrhg', 'Dfrihgkdj'],
                },
            ],
        },
        {
            login: 'UtilisateurNonparticipatif',
            realname: 'IKjfdg jkljfg',
            email: 'a050a37aac02b8fa4db6a009156c962e',
            password: 'c1e242a344781aa9d2dca6412bd187bf',
        },
    ],
}

export const store = createStore(reducer, initialState, window.devToolsExtension && window.devToolsExtension())

// Reducer
function reducer(state, action) {
    switch (action.type) {
        case 'ADD_POST':
            return {
                ...state,
                posts: [...state.posts, action.payload],
            }
        case 'VOTE_POST':
            return {
                ...state,
                posts: state.posts.map(post => (post.id === action.payload ? { ...post, voted: !post.voted } : post)),
            }
        case 'DELETE_POST':
            return {
                ...state,
                posts: state.posts.filter(post => post.id !== action.payload),
            }
        default:
            return state
    }
}
// Actions
export const addpostAction = post => ({
    type: 'ADD_POST',
    payload: post,
})

export const togglepostComplete = postId => ({
    type: 'VOTE_POST',
    payload: postId,
})

export const deletepostAction = postId => ({
    type: 'DELETE_POST',
    payload: postId,
})
