import React from 'react'

// components
import Card from '../molecules/Card'

// picture randomizer
import { randomPicture } from '../Functions'

// styles
import './Homepage.scss'

// component
const Homepage = () => {
    return (
        <>
            <Card top={'house'} ranked={false} authorPicture={randomPicture()} author="lorede" postPicture={randomPicture()} />
            <Card top={'shaved'} ranked={true} authorPicture={randomPicture()} author="ipsum_e" postPicture={randomPicture()} />
            <Card top={'car'} ranked={false} authorPicture={randomPicture()} author="Inodlorre" postPicture={randomPicture()} />
            <Card top={'blond'} ranked={true} authorPicture={randomPicture()} author="Acquismete" postPicture={randomPicture()} />
            <Card top={'freak'} ranked={false} authorPicture={randomPicture()} author="Pelletensque" postPicture={randomPicture()} />
        </>
    )
}

export default Homepage
