import React, { Fragment } from 'react'

const Profile = () => {
    return (
        <Fragment>
            <h2>profile</h2>
        </Fragment>
    )
}

export default Profile
