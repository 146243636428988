import uuid from 'uuid/v4'
import { ADD_POST } from './actionTypes'

export const addPost = content => ({
    type: ADD_POST,
    payload: {
        id: uuid(),
        content,
    },
})
