import React, { Fragment } from 'react'

const About = () => {
    return (
        <Fragment>
            <h2>About</h2>
        </Fragment>
    )
}

export default About
