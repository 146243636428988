import React from 'react'

// components
import Button from '../atoms/Button'

// styles
import CSS_NAVBAR from './Navbar.module.scss'

// component
const Navbar = props => {
    return (
        <nav className={CSS_NAVBAR.main}>
            <ul className={CSS_NAVBAR.list}>
                <li className={CSS_NAVBAR.item}>
                    <Button className={CSS_NAVBAR.link} fa="newspaper" to="/">
                        <span className={CSS_NAVBAR.text}>News</span>
                    </Button>
                </li>
                <li className={CSS_NAVBAR.item}>
                    <Button className={CSS_NAVBAR.link} fa="network-wired" to="/tops">
                        <span className={CSS_NAVBAR.text}>Tops</span>
                    </Button>
                </li>
                <li className={`${CSS_NAVBAR.item} ${CSS_NAVBAR.itemCenter}`}>
                    <Button className={CSS_NAVBAR.link} fa="camera-retro" to="/post"></Button>
                </li>
                <li className={CSS_NAVBAR.item}>
                    <Button className={CSS_NAVBAR.link} fa="portrait" to="/profile">
                        <span className={CSS_NAVBAR.text}>Profile</span>
                    </Button>
                </li>
                <li className={CSS_NAVBAR.item}>
                    <Button className={CSS_NAVBAR.link} fa="cog" to="/settings">
                        <span className={CSS_NAVBAR.text}>Settings</span>
                    </Button>
                </li>
            </ul>
        </nav>
    )
}

export default Navbar
