import React, { Fragment, useState } from 'react'

// database connector
import db from '../../utils/firebase.config'

// component
const Settings = () => {
    const [fullname, setFullname] = useState('')
    const [email, setEmail] = useState('')

    const addUser = e => {
        e.preventDefault()
        db.collection('users')
            .add({ fullname: fullname, email: email })
            .then(function(docRef) {
                console.log(docRef.id)
            })
            .catch(function(error) {
                console.error('POST NOT ADDED: ', error)
            })
        setFullname('')
        setEmail('')
    }

    return (
        <Fragment>
            <h2>settings</h2>
            <form onSubmit={addUser}>
                <input type="text" name="fullname" value={fullname} onChange={event => setFullname(event.target.value)} placeholder="Full name" />
                <input type="email" name="email" value={email} onChange={event => setEmail(event.target.value)} placeholder="Full name" />
                <button type="submit">OK</button>
            </form>
        </Fragment>
    )
}

export default Settings
