import React from 'react'

import CSS_AUTHOR from './Author.module.scss'

const Author = props => {
    return (
        <a className={CSS_AUTHOR.main} href={'/users/' + props.author}>
            <div className={CSS_AUTHOR.picture}>
                <img className={CSS_AUTHOR.media} alt={props.author + "'s profile picture"} src={props.picture} />
            </div>
            <div className={CSS_AUTHOR.nickname}>{props.author}</div>
        </a>
    )
}

export default Author
