import React, { Fragment } from 'react'

const Notfound = () => {
    return (
        <Fragment>
            <h2>Error, this page is not found.</h2>
        </Fragment>
    )
}

export default Notfound
