import React, { Fragment, useState } from 'react'

// generate an unique id
import uuid from 'uuid/v4'

import { connect } from 'react-redux'
// import { addTodoAction } from '../redux';

// component
const Users = () => {
    const [crap, setCrap] = useState('YEAH')
    // clg

    const handleClick = event => {
        setCrap('AllDone')
    }

    console.log(crap)

    return (
        <Fragment>
            <h2 onClick={handleClick}>List of subscribers</h2>
            <ul>
                <li key={uuid()}>
                    <a href="/users/Kriffin">Kriffin</a>
                </li>
                <li key={uuid()}>
                    <a href="/users/GroenlandPowa">GroenlandPowa</a>
                </li>
            </ul>
            <h2>List of followers</h2>
            <ul>
                <li key={uuid()}>
                    <a href="/users/GordonCreek">GordonCreek</a>
                </li>
                <li key={uuid()}>
                    <a href="/users/KellyF">KellyF</a>
                </li>
            </ul>
        </Fragment>
    )
}

export default connect()(Users)
