import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
// import store from './redux/store'
import { store } from './utils/redux'

import Routes from './utils/Routes'

// styles
import './App.scss'

// components
import OrganismsHeader from './components/organisms/Header'
import OrganismsFooter from './components/organisms/Footer'
import NavBar from './components/molecules/Navbar'

// application
const App = () => {
    return (
        <Provider store={store}>
            <Router>
                <OrganismsHeader />
                <main className="content">
                    <Routes />
                </main>
                <hr />
                <OrganismsFooter />
                <NavBar />
            </Router>
        </Provider>
    )
}

export default App
