import React, { Fragment, useState } from 'react'

// components
import { NavLink } from 'react-router-dom'
import Author from 'components/atoms/Author'
import Vote from 'components/atoms/Vote'

// styles
import styled from 'styled-components'
import 'animate.css' // FIX correct it to react animations
import CSS_CARD from './Card.module.scss'

// assets
import logo from '../../assets/logo.svg'

// component
const Card = props => {
    let clickCount = 0
    let singleClickTimer
    const [ranked, setRanked] = useState(props.ranked)
    const [postPictureLoaded, setPostPictureLoaded] = useState(false)

    const handleClick = event => {
        clickCount++
        if (clickCount === 1) {
            singleClickTimer = setTimeout(() => {
                clickCount = 0
            }, 400)
        } else if (clickCount === 2) {
            setRanked(!ranked)
            clickCount = 0
            clearTimeout(singleClickTimer)
        }
    }

    const StyleLoader = styled.div`
        background-color: #999999;
        background-image: url(${logo});
        background-size: 100%;
        background-position: center center;
        opacity: 0.3;
        min-width: 100vw;
        min-height: 100vw;
        height: 100%;
        width: 100%;
    `

    return (
        <article className={ranked ? `${CSS_CARD.main} ranked animated fadeIn` : CSS_CARD.main}>
            <div className={CSS_CARD.picture} onClick={handleClick}>
                <span className={ranked ? `${CSS_CARD.main} ${CSS_CARD.pictureRank} animated fadeOutUp` : CSS_CARD.pictureRankHidden}>+1</span>
                {postPictureLoaded || <StyleLoader />}
                <img
                    onLoad={() => setPostPictureLoaded(true)}
                    className={ranked === true ? 'animated fadeIn' : ''}
                    width="100vw"
                    height="100vw"
                    src={props.postPicture}
                    alt={props.top + ' - ' + props.author}
                />
            </div>
            <div className={props.author ? CSS_CARD.meta : `${CSS_CARD.meta} ${CSS_CARD.metaNoAuthor}`}>
                {props.author ? <Author url="/" author={props.author} picture={props.authorPicture} /> : <Fragment />}
                {props.top ? (
                    <NavLink className={CSS_CARD.top} to={'/tops/' + props.top}>
                        Best {props.top}?
                    </NavLink>
                ) : (
                    <Fragment />
                )}
                <div onClick={() => setRanked(!ranked)}>
                    <Vote active={ranked === true} />
                </div>
            </div>
        </article>
    )
}

export default Card
