import React, { useState } from 'react'

// redux
import { connect } from 'react-redux'
import { addPost } from '../../redux/actions'

// database connector
import db from '../../utils/firebase.config'

// components
import Button from '../atoms/Button'
import Textarea from '../atoms/Textarea'

// picture randomizer
import { randomPicture } from '../Functions'

// component
function Post() {
    const author = 'HelloDude'

    const [mediaurl, setMediaurl] = useState(randomPicture)

    const [tops, setTops] = useState([])
    const [description, setDescription] = useState('')

    /**
     * NOTE POST UPDATE
     */
    /*
  const postUpdate = e => {
    e.preventDefault()
    db.collection('posts').doc('wCw1t4nSNY6bTxpjUHNe').onSnapshot(
      docSnapshot => {
        console.log(`INFO MISES a JOUR  doc snapshot: ${docSnapshot}`)
      },
      err => {
        console.log(`Encountered error: ${err}`)
      },
    )
  }
  */

    /**
     * NOTE POST ADDING
     */
    const addPost = e => {
        e.preventDefault()
        db.collection('posts')
            .add({
                author: author,
                description: description,
                mediaurl: randomPicture(),
                tops: tops,
                publicationDate: new Date(),
            })
            .then(function(docRef) {
                console.log(
                    '%c POST ADDED ! 😊',
                    'padding: 10px 5px 5px;background: #4caf50;-webkit-box-decoration-break: clone;box-decoration-break: clone;font-size: 30px;font-weight:bold;line-height: 1.06;text-align:center;color:white;font-family:Arial',
                    'https://console.firebase.google.com/project/topizr-reactjs/database/firestore/data~2Fposts~2F' + docRef.id,
                )
            })
            .catch(function(error) {
                console.error(
                    '%c POST NOT ADDED ! 😒',
                    'padding: 10px 5px 5px;background: #f44336;-webkit-box-decoration-break: clone;box-decoration-break: clone;font-size: 30px;font-weight:bold;line-height: 1.06;text-align:center;color:white;font-family:Arial',
                    error,
                )
            })
    }

    /**
     * NOTE ALL POSTs DELETION
     */
    const removeAllPost = e => {
        e.preventDefault()
        db.collection('posts')
            .where('author', '==', 'HelloDude')
            .get()
            .then(function(querySnapshot) {
                var batch = db.batch()
                querySnapshot.forEach(function(doc) {
                    batch.delete(doc.ref)
                })
                console.log(
                    '%c ALL POSTS ARE DELETED ! 😊',
                    'padding: 10px 5px 5px;background: #4caf50;-webkit-box-decoration-break: clone;box-decoration-break: clone;font-size: 30px;font-weight:bold;line-height: 1.06;text-align:center;color:white;font-family:Arial',
                )
                return batch.commit()
            })
            .catch(function(error) {
                console.error(
                    '%c ALL POSTS ARE NOT DELETED ! 😒',
                    'padding: 10px 5px 5px;background: #f44336;-webkit-box-decoration-break: clone;box-decoration-break: clone;font-size: 30px;font-weight:bold;line-height: 1.06;text-align:center;color:white;font-family:Arial',
                    error,
                )
            })
    }

    const handleChange = event => {
        setTops(event.target.value.split(' '))
    }

    return (
        <>
            <h2>Post</h2>
            <form onSubmit={addPost}>
                <div className="hide">
                    <label htmlFor="media">Media</label>
                    <input type="text" name="media" value={mediaurl} onChange={event => setMediaurl(event.target.value)} />
                </div>
                <Textarea onChange={e => setDescription(e.target.value)} placeholder="Description" value={description} />
                <Textarea onChange={handleChange} placeholder="Tags" value={tops.join(' ')} />
                <Button type="submit">OK</Button>
                <Button onClick={removeAllPost}>Remove all posts</Button>
            </form>
        </>
    )
}

export default connect(
    null,
    { addPost },
)(Post)
