import React from 'react'

const Tops = ({ match }) => (
    <div className="Tops">
        <h2>Tops</h2>
        <center>
            <p>Make two panels : "Explore" and "Suscribed"</p>
        </center>
        <ul>
            <li>
                <a href="/tops/Blond">Blond</a>
            </li>
            <li>
                <a href="/tops/Bearded">Bearded</a>
            </li>
        </ul>
    </div>
)

export default Tops
